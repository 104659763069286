export const CashBankCoa = {
  ACC_CASH: 110100,
  ACC_BANK: 110200,
}

export const Coa = {
  CASH_BOTTOM_BOUND: 110100,
  PETTY_CASH: 110102,
  CASH_UPPER_BOUND: 110200,

  BANK_BOTTOM_BOUND: 110200,
  BANK_UPPER_BOUND: 110300,

  INVESTATION_BOTTOM_BOUND: 110300,
  INVESTATION_UPPER_BOUND: 110500,

  ACC_RECEIVABLE: 110500,
  ACC_STOCK: 110800,
  // TODO might as well define bounds for other accounts
  ASSET_BOTTOM_BOUND: 110000,
  ASSET_UPPER_BOUND: 200000,

  LIABILITY_BOTTOM_BOUND: 210000,
  LIABILITY_UPPER_BOUND: 300000,

  EQUITY_BOTTOM_BOUND: 310000,
  EQUITY_UPPER_BOUND: 400000,

  INCOME_BOTTOM_BOUND: 400000,
  INCOME_UPPER_BOUND: 500000,

  OPERATIONAL_INCOME_BOTTOM_BOUND: 400000,
  OPERATIONAL_INCOME_UPPER_BOUND: 420000,

  NON_OPERATIONAL_INCOME_BOTTOM_BOUND: 500000,
  NON_OPERATIONAL_INCOME_UPPER_BOUND: 520000,

  EXPENSE_BOTTOM_BOUND: 500000,
  EXPENSE_UPPER_BOUND: 600000,

  OPERATIONAL_EXPENSE_BOTTOM_BOUND: 420000,
  OPERATIONAL_EXPENSE_UPPER_BOUND: 500000,

  NON_OPERATIONAL_EXPENSE_BOTTOM_BOUND: 520000,
  NON_OPERATIONAL_EXPENSE_UPPER_BOUND: 600000,

  FIXED_ASSET_BOTTOM_BOUND: 120000,
  FIXED_ASSET_UPPER_BOUND: 130000,

  SAVING_BOTTOM_BOUND: 210200,
  SAVING_UPPER_BOUND: 210300,
  VOLUNTEER_SAVINGS: 210201,
  HOLYDAY_SAVINGS: 210202,
  EDUCATION_SAVINGS: 210203,

  TERM_SAVING_BOTTOM_BOUND: 210300,
  TERM_SAVING_UPPER_BOUND: 210400,

  LIABILITY: 220100,

  MEMBER_CAPITAL_BOTTOM_BOUND: 310000,
  MEMBER_CAPITAL_UPPER_BOUND: 310300,
  PRINCIPAL_SAVINGS: 310100,
  MANDATORY_SAVINGS: 310200,
  INCOME_STATEMENT_ACCUMULATION: 330000,

  ACC_NET_PROFIT: 550000,

  // TODO might as well define bounds for other accounts

  // COA Codes
  // Asset
  ACC_GRUP_AKTIVA: 100000,
  ACC_GRUP_ACTIVA_LANCAR: 110000,
  ACC_GRUP_ACTIVA_TETAP: 120000,
  ACC_GRUP_ASSET_LAIN_LAIN: 130000,

  //cash group
  ACC_ASET_LANCAR: 110000,
  ACC_KAS: 110100,
  ACC_KAS_BESAR: 110101,
  ACC_KAS_KECIL: 110102,
  ACC_BANK: 110200,
  ACC_PPH_DIBAYAR_DIMUKA_PASAL_25: 110300,
  ACC_BIAYA_DIBAYAR_DI_MUKA: 110400,
  ACC_ASET_LANCAR_LAIN: 110500,
  ACC_GRUP_ASET_TIDAK_LANCAR: 120000,
  ACC_GRUP_INVESTASI_JANGKA_PANJANG: 120100,

  ACC_PENYUSUTAN_AWAL: 120200, //begin adjusted acc
  ACC_PROPERTI_INVESTASI: 120200,
  ACC_AKM_PENYUSUTAN_PROPERTI_INVESTASI: 120300,
  ACC_GRUP_AKTIVA_TETAP: 120400,
  ACC_TANAH_HAK_ATAS_TANAH: 120410,
  ACC_AKM_PENYUSUTAN_TANAH_HAK_ATAS_TANAH: 120411,
  ACC_BANGUNAN: 120420,
  ACC_AKM_PENYUSUTAN_BANGUNAN: 120421,
  ACC_MESIN_DAN_KENDARAAN: 120430,
  ACC_AKM_PENYUSUTAN_MESIN_DAN_KENDARAAN: 120431,
  ACC_INVENTARIS_DAN_PERALATAN_KANTOR: 120440,
  ACC_AKM_PENYUSUTAN_INVENTARIS_DAN_PERALATAN_KANTOR: 120441,
  ACC_ASET_TIDAK_BERWUJUD: 120450,
  ACC_AKM_AMORTISASI_ASET_TIDAK_BERWUJUD: 120451,
  ACC_GRUP_ASET_TIDAK_LANCAR_LAIN: 130000,

  //liability group
  ACC_GRUP_KEWAJIBAN: 200000,
  ACC_GRUP_KEWAJIBAN_LANCAR: 210000,
  ACC_GRUP_SIMPANAN_SISWA: 210100,
  ACC_SIMPANAN_WADIAH: 210101,
  ACC_SIMPANAN_PENDIDIKAN: 210102,
  ACC_SIMPANAN_QURBAN: 210103,
  ACC_HUTANG_PPH_FINAL: 210300,
  ACC_HUTANG_PPN: 210400,
  ACC_HUTANG_ZAKAT: 210500,
  ACC_HUTANG_INFAQ_SHODAQOH: 210600,
  ACC_HUTANG_NON_DAGANG: 210700,
  ACC_KEWAJIBAN_JANGKA_PANJANG: 220000,
  ACC_HUTANG_PIHAK_KETIGA: 220100,
  ACC_GRUP_KEWAJIBAN_LAIN_LAIN: 230000,

  //equity group
  ACC_GRUP_MODAL: 300000,
  ACC_MODAL_SENDIRI: 310000,
  ACC_SUMBANGAN_OPERASIONAL: 310100,
  ACC_SUMBANGAN_INFRASTRUKTUR: 310200,
  ACC_SUMBANGAN_KHUSUS: 310300,
  ACC_DANA_CADANGAN: 310400,
  ACC_SHU_TIDAK_DIBAGI: 310500,
  ACC_MODAL_HIBAH: 310600,
  ACC_AKM_LABA_RUGI: 320000,

  //operational incomes and expenses group
  ACC_GRUP_PENDAPATAN_DAN_BEBAN_OPERASIONAL: 400000,
  ACC_GRUP_PENDAPATAN_OPERASIONAL: 410000,
  ACC_PENDAPATAN_BAGI_HASIL: 410100,
  ACC_PENDAPATAN_MARK_UP: 410200,
  ACC_PENDAPATAN_UJROH: 410300,
  ACC_PENDAPATAN_ADMINISTRASI: 410400,

  ACC_GRUP_BIAYA_OPERASIONAL: 420000,
  ACC_BEBAN_USAHA_DAN_ORGANISASI: 420100,
  ACC_BIAYA_GAJI_KARYAWAN: 420101,
  ACC_BIAYA_BPJS_KESEHATAN: 420102,
  ACC_BIAYA_BPJS_KETENAGAKERJAAN: 420103,
  ACC_BIAYA_ATK: 420104,
  ACC_BIAYA_LISTRIK_INTERNET: 420105,
  ACC_BIAYA_KONSUMSI: 420106,
  ACC_BIAYA_TRANSPORT: 420107,
  ACC_BIAYA_THR: 420108,
  ACC_BIAYA_OPERASIONAL_LAIN: 420109,

  ACC_GRUP_BIAYA_PENYUSUTAN_INVESTASI_JANGKA_PANJANG: 420200,

  ACC_GRUP_BIAYA_PENYUSUTAN: 420300,
  ACC_BIAYA_PENYUSUTAN_PROPERTI_INVESTASI: 420301,
  ACC_BIAYA_PENYUSUTAN_TANAH_HAK_ATAS_TANAH: 420302,
  ACC_BIAYA_PENYUSUTAN_BANGUNAN: 420303,
  ACC_BIAYA_PENYUSUTAN_MESIN_DAN_KENDARAAN: 420304,
  ACC_BIAYA_PENYUSUTAN_INVENTARIS_DAN_PERALATAN_KANTOR: 420305,
  ACC_BIAYA_AMORTISASI_ASET_TIDAK_BERWUJUD: 420306,
  ACC_GRUP_BIAYA_PERAWATAN: 420400,
  ACC_BIAYA_PERAWATAN_INVENTARIS_DAN_PERALATAN_KANTOR: 420401,

  //non operational incomes and expenses group
  ACC_GRUP_PENDAPATAN_DAN_BIAYA_NON_OPERASIONAL: 500000,
  ACC_GRUP_PENDAPATAN_NON_OPERASIONAL: 510000,
  ACC_PENDAPATAN_BAGI_HASIL_BANK: 510100,
  ACC_PENDAPATAN_INVESTASI_JANGKA_PANJANG: 510200,
  ACC_GRUP_BIAYA_NON_OPERASIONAL: 520000,
  ACC_BIAYA_RTGS: 520100,
  ACC_BIAYA_ADM_SMS_BANK: 520200,
  ACC_GROUP_BIAYA_PAJAK: 520300,
  ACC_BIAYA_PPH_FINAL_BAGI_HASIL_BANK: 520301,
  ACC_BIAYA_PPH_PASAL_4: 520302,
  ACC_BIAYA_ZAKAT: 520303,
  ACC_BIAYA_INFAQ_SHODAQOH: 520304,
  ACC_BIAYA_ADM_KEWAJIBAN: 520400,
  ACC_BIAYA_BUNGA_KEWAJIBAN: 520500,
  ACC_LABA_RUGI_PENJUALAN_ASET: 520600,
  ACC_LABA_RUGI_REVALUASI_ASET: 520700,
  ACC_LABA_RUGI: 530000,

  //fake account,just for help reporting
  BALANCE_SHEET_SUMMARY: 399000,
  TOTAL_OPERATIONAL_INCOME: 419990,
  TOTAL_OPERATIONAL_EXPENSES: 429900,
  INCOME_AFTER_OPERATIONAL_EXPENSES: 429991,
  TOTAL_OTHER_INCOME_AND_EXPENSES: 519990,
  PROFIT_BEFORE_TAX: 540499,
  CORPORATE_TAX_PAYABLE: 540500,
  PROFIT_AFTER_TAX: 550000,
  TOTAL_SUMMARY: 699999,
}

export const Gender = {
  Male: 0,
  Female: 1,
}

export const MaritalStatus = {
  Single: 0,
  Married: 1,
  Divorced: 2,
}

export const UserStatus = {
  Pending: 0,
  Activated: 1,
  Deactivated: 2,
  Deleted: 3,
}

export const ProductType = {
  Product: 1,
  DirectMaterial: 2,
}

export const ProductionType = {
  NEW: 1,
  REPACK: 2,
  REPAIR: 3,
}

export const ProductSaleOrderStatus = {
  PENDING: 1,
  PAID: 2,
  PRINT: 3,
  SEND: 4,
  OK: 5,
}

export const BankTransaction = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
  ADMINISTRATIVE_COST: 3,
  INTEREST: 4,
  TRANSFER: 5,
}

export const CodeMarketerDept = 'DPT0000005'

export const UserStatusLabel = {
  [UserStatus.Pending]: 'Pending',
  [UserStatus.Activated]: 'Activated',
  [UserStatus.Deactivated]: 'Deactivated',
  [UserStatus.Deleted]: 'Deleted',
}

export const UserStatusColor = {
  [UserStatus.Pending]: 'warning',
  [UserStatus.Activated]: 'success',
  [UserStatus.Deactivated]: 'danger',
  [UserStatus.Deleted]: 'danger',
}

export const GrantCapitalStatus = {
  Pending: 0,
  Posted: 1,
}

export const GrantCapitalStatusLabel = {
  [GrantCapitalStatus.Pending]: 'Pending',
  [GrantCapitalStatus.Posted]: 'Posted',
}

export const GrantCapitalStatusColor = {
  [GrantCapitalStatus.Pending]: 'warning',
  [GrantCapitalStatus.Posted]: 'success',
}

export const TermSavingStatus = {
  Pending: 0,
  Posted: 1,
  Closed: 2,
  Blocked: 3,
}

export const TermSavingStatusLabel = {
  [TermSavingStatus.Pending]: 'Pending',
  [TermSavingStatus.Posted]: 'Active',
  [TermSavingStatus.Closed]: 'Closed',
  [TermSavingStatus.Blocked]: 'Bloked',
}

export const TermSavingStatusColor = {
  [TermSavingStatus.Pending]: 'warning',
  [TermSavingStatus.Posted]: 'success',
  [TermSavingStatus.Closed]: 'danger',
  [TermSavingStatus.Blocked]: 'danger',
}

export const SavingStatus = {
  Active: 0,
  Closed: 1,
  Blocked: 2,
}

export const SavingStatusLabel = {
  [SavingStatus.Active]: 'Active',
  [SavingStatus.Closed]: 'Closed',
  [SavingStatus.Blocked]: 'Blocked',
}

export const SavingStatusColor = {
  [SavingStatus.Active]: 'success',
  [SavingStatus.Closed]: 'secondary',
  [SavingStatus.Blocked]: 'danger',
}

export const SavingTransaction = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
  SAVING_INTEREST: 3,
  ADMINISTRATIVE_COST: 4,
  CLOSING: 5,
  CLOSING_COST: 6,
  TERM_SAVING_INTEREST: 33,
}

export const LendingStatus = {
  Pending: 0,
  Approved: 1,
  Disbursed: 2,
  Fulfilled: 3,
  Rejected: 4,
}

export const LendingStatusLabel = {
  [LendingStatus.Pending]: 'Pengajuan',
  [LendingStatus.Approved]: 'Disetujui',
  [LendingStatus.Disbursed]: 'Dicairkan',
  [LendingStatus.Fulfilled]: 'Lunas',
  [LendingStatus.Rejected]: 'Ditolak',
}

export const LendingStatusColor = {
  [LendingStatus.Pending]: 'warning',
  [LendingStatus.Approved]: 'success',
  [LendingStatus.Disbursed]: 'success',
  [LendingStatus.Fulfilled]: 'secondary',
  [LendingStatus.Rejected]: 'danger',
}

export const DeptCode = {
  CODE_DEPT_SUPER: 'DPT0000000',
  CODE_DEPT_KOMITE: 'DPT0000001',
  CODE_DEPT_TENDIK: 'DPT0000002',
  CODE_DEPT_TATA_USAHA: 'DPT0000003',
  CODE_DEPT_KEUANGAN: 'DPT0000004',
}

export const Style = {
  fontSize: 14,
  size: 'sm',
}

export const AppSetting = {
  GENERAL: 3,

  //general
  BEGINNING_BALANCE: 35,
  BACK_DATE: 36,
}

export const BeginningBalance = {
  BB_FLAG: 3,
}

export const GroupUserType = {
  GROUP_TRUSTIE_TYPE: 1,
  GROUP_EMPLOYEE_TYPE: 2,
  GROUP_STUDENT_TYPE: 3,
}

export const GroupIndex = {
  SUPERUSER_IDX: 1,
  ADMIN_IDX: 2,
  TRUSTIE_IDX: 3,
  STUDENT_IDX: 4,
  TEACHER_IDX: 5,
  ADMINISTRATION_IDX: 6,
  FINANCE_IDX: 7,
}

export const UserType = {
  USER_TRUSTIE_TYPE: 1,
  USER_EMPLOYEE_TYPE: 2,
  USER_STUDENT_TYPE: 3,
}

export const DeptGroup = {
  DEPT_TRUSTIE_GROUP: 1,
  DEPT_EMPLOYEE_GROUP: 2,
}

export const LiabilityTransactionCode = {
  INITIAL_TRANSACTION: 0,
  INSTALLMENT_TRANSACTION: 1,
  REVERT_TRANSACTION: 2,
}

export const LiabilityTransactionOrigin = {
  PRINCIPAL_ORIGIN: 0,
  FEE_ORIGIN: 1,
}

export const StudentStatus = {
  ACTIVE_STATUS: 0,
  GRADUATE_STATUS: 1,
  CHANGE_STATUS: 2,
  DROP_OUT_STATUS: 3,
}

export const TuitionCategory = {
  NON_DEFAULT: 0,
  DEFAULT: 1,

  TUITION_TYPE_1: 1,
  TUITION_TYPE_2: 2,
  TUITION_TYPE_3: 3,
  TUITION_TYPE_4: 4,

  TUITION_NAME_1: 'Kategori 1',
  TUITION_NAME_2: 'Kategori 2',
  TUITION_NAME_3: 'Kategori 3',
  TUITION_NAME_4: 'Kategori 4',
}

export const StudentParent = {
  STUDENT_FATHER: 1,
  STUDENT_MOTHER: 2,
  STUDENT_GUARDIAN: 3,
}
